import React from "react"
import ReactMarkdown from "react-markdown"

const QuickPost = ({ musicTitle, albumTitle, artist, producer, director }) => {
  const quickPostContent = `Le clip de **${musicTitle}** de **${artist}**, est maintenant disponible.
  
    
  ${
    albumTitle !== null && albumTitle.length !== 0
      ? `**${musicTitle}**, est tiré de l'album **${albumTitle}**.`
      : ""
  }

  ${
    producer !== null &&
    director !== null &&
    producer.length !== 0 &&
    director.length === 0
      ? ` Le clip est produit par **${producer}**.`
      : ""
  }

  ${
    producer !== null &&
    director !== null &&
    producer.length !== 0 &&
    director.length !== 0
      ? ` Le clip est produit par **${producer}** et réalisé par **${director}**.`
      : ""
  }

  ${
    producer !== null &&
    director !== null &&
    producer.length === 0 &&
    director.length !== 0
      ? ` Le clip est réalisé par **${director}**.`
      : ""
  }
  `

  return (
    <ReactMarkdown
      className="is-size-5 contentArticle"
      source={quickPostContent}
      escapeHtml={true}
    />
  )
}

export default QuickPost
