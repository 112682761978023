import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import { css } from "@emotion/core"
import {
  createSlug,
  clearHtmlAndshrink,
  contentQuickPost,
} from "../components/utils"
import { colors } from "../components/styles"

const CardSmall = ({ news, displaySneakPeek }) => {
  const slug = createSlug(news.type.name, news.createdAt, news.title)
  // const content = clearHtmlAndshrink(news.content, displaySneakPeek)

  return (
    <Link className="cardSmall" css={cardSmall} to={slug}>
      <article className="media is-marginless">
        <figure className="media-left image is-64x64">
          <Img
            className="card-image"
            fluid={news.image.childImageSharp.fluid}
          />
        </figure>
        <div className="media-content">
          <div className="content">
            <h6 className="title is-6">{news.bait ? news.bait : news.title}</h6>
            <h6 className="subtitle is-6">
              {news.content &&
                clearHtmlAndshrink(
                  contentQuickPost(
                    news.quick_post,
                    news.music_title,
                    news.content
                  ),
                  displaySneakPeek
                )}
            </h6>
          </div>
        </div>
      </article>
    </Link>
  )
}

export default CardSmall

const cardSmall = css`
  &.cardSmall {
    flex: 1;
    background-color: ${colors.primary};
    article.media {
      padding: 10px;
      padding-right: 0;
      background: #fff;
      transition: transform 0.3s, background-color 0.3s ease-in-out;
      &:hover {
        transform: translateX(8px);
        background-color: #eee;
        /* border-left: solid salmon 5px; */
      }
    }
    figure {
      .card-image {
        height: 64px;
        img {
          /* max-height: 200px; */
          width: auto;
          margin: auto;
        }
      }
    }
    .media-content {
      overflow: hidden, .subtitle {
        margin-bottom: 0;
      }
    }
  }
`
