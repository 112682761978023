import React, { useState, useEffect } from "react"
import { css } from "@emotion/core"
import { colors, mq } from "../components/styles"

const AmazonAds = ({ ads }) => {
  const [adToDisplay, setAdToDisplay] = useState(
    Math.floor(Math.random() * ads.length)
  )
  const [pauseAd, setPauseAd] = useState(false)

  const handleMouseHover = () => {
    setPauseAd(!pauseAd)
  }

  useEffect(() => {
    if (pauseAd) {
      return
    }

    const interval = setInterval(() => {
      // if adToDisplay gets to the last ad item, go back to the furst item
      let numToDisplay = adToDisplay === ads.length - 1 ? 0 : adToDisplay + 1
      setAdToDisplay(numToDisplay)
    }, 10000)

    return () => {
      clearInterval(interval)
    }
  }, [ads, adToDisplay, pauseAd])

  return (
    <a
      data-active={adToDisplay}
      href={`https://www.amazon.fr/a/dp/${ads[adToDisplay].id}/ref=as_li_ss_tl?th=1&linkCode=ll1&tag=francemixtape-21&language=fr_FR`}
      target="_blank"
      rel="noopener noreferrer"
      css={amazonAdsCss}
      onMouseEnter={handleMouseHover}
      onMouseLeave={handleMouseHover}
    >
      <div className="amzn">
        <div className="columns is-gapless">
          <div className="column is-5 amzn-col-infos">
            <header className="logo">
              <img
                src={`/assets/images/amzn/amazonlogo.png`}
                alt="Amazon Logo"
              />
            </header>
            <div className="content title is-5 is-size-6">
              {ads[adToDisplay].title}
            </div>
            <footer className="price">
              {ads[adToDisplay].priceOriginal && (
                <p className="price-old is-size-7">
                  Prix: <span>{ads[adToDisplay].priceOriginal}</span>
                </p>
              )}
              {ads[adToDisplay].priceWithDiscount && (
                <p className="price has-discount is-size-7">
                  Nouveau Prix:{" "}
                  <span className="is-size-6">
                    {ads[adToDisplay].priceWithDiscount}
                  </span>
                </p>
              )}
              {ads[adToDisplay].price && (
                <p className="price is-size-6">
                  Prix: <span>{ads[adToDisplay].price}</span>
                </p>
              )}
            </footer>
          </div>
          <div className="column is-7 amzn-col-action">
            <div className="product-image">
              <img
                src={`/assets/images/amzn/${ads[adToDisplay].image}`}
                alt="product"
              />
            </div>
            <p className="is-size-7 has-text-centered">4,4 sur 5 étoiles</p>
            <div className="link-gotoamazon"> Voir sur amazon.fr</div>
            <div className="info-legal">lien sponsorisé</div>
          </div>
        </div>
        <div className={`${pauseAd ? "" : "am-loader"}`}></div>
      </div>
    </a>
  )
}

export default AmazonAds

const amazonAdsCss = css`
  a {
    color: inherit;
  }
  .amzn {
    position: relative;
    margin: 20px auto;
    color: ${colors.amazon_black};
    border: solid 2px ${colors.amazon_orange};
    padding: 20px;
    padding-right: 0;
    max-width: 380px;
    .am-loader {
      width: 0%;
      height: 3px;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      background: ${colors.amazon_orange};
      animation: 10s ease-in infinite animate_loader;
    }
    @keyframes animate_loader {
      from {
        width: 0%;
      }
      to {
        width: 100%;
      }
    }
    .columns {
      display: flex;
      min-height: 300px;
    }
    .amzn-col-infos {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      header.logo {
        img {
          max-width: 100px;
        }
      }
      .content {
        &.title {
          height: 180px;
          overflow: auto;
        }
      }
      footer.price {
        ${mq[1]} {
          margin: 10px 0;
        }
        .price-old {
          color: inherit;
          span {
            text-decoration: line-through;
          }
        }
        .price {
          &.has-discount {
            color: ${colors.amazon_red};
          }
          span {
            font-weight: bold;
          }
        }
      }
    }

    .amzn-col-action {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .link-gotoamazon {
        background: ${colors.amazon_black};
        color: ${colors.white};
        display: flex;
        border: none;
        border-radius: 5px;
        justify-content: center;
        text-align: center;
        margin: 0 10px;
        padding: 5px 5px;
        font-size: 0.85rem;
        ${mq[1]} {
          font-size: 1rem;
          margin: 10px auto;
          padding: 15px 20px;
        }

        &:hover {
          background: black;
        }
      }
      .info-legal {
        position: absolute;
        background: #eeeeee;
        opacity: 0.6;
        color: darkgray;
        font-size: 10px;
        top: 0;
        right: 0;
        padding: 3px 5px;
      }
    }
  }
`
