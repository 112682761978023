import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { css } from "@emotion/core"

const TweetButton = ({ slug, bait, emoji }) => {
  const data = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "Twitter_Social_Icon_Circle_Color.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `)

  const checkEmoji = () =>
    !emoji
      ? `https://twitter.com/intent/tweet?text=${bait} https://hauteculture.com${slug}`
      : `https://twitter.com/intent/tweet?text=${emoji} ${bait} https://hauteculture.com${slug}`

  return (
    <div css={TweetButtonCss} className="container is-inline-block">
      <a title="Tweet cet article" target="_blank" rel="noopener noreferrer" href={checkEmoji()}>
        <figure className="image is-32x32">
          <Img
            className="img"
            fluid={data.file.childImageSharp.fluid}
            alt={"twitter-logo"}
          />
        </figure>
      </a>
    </div>
  )
}

export default TweetButton

const TweetButtonCss = css`
  img {
    border-radius: 33px;
  }

  .img {
    transition: transform 0.1s ease-in-out;
    &:hover {
      transform: translateY(-3px);
    }
  }
`
