import React from "react"
import InstagramEmbed from "react-instagram-embed"
import { TwitterTweetEmbed, TwitterVideoEmbed } from "react-twitter-embed"
import ReactSoundcloud from 'react-soundcloud-embed';
// import { TwitterTimelineEmbed, TwitterShareButton, TwitterFollowButton, TwitterHashtagButton, TwitterMentionButton, TwitterTweetEmbed, TwitterMomentShare, TwitterDMButton, TwitterVideoEmbed, TwitterOnAirButton } from 'react-twitter-embed';
const EmbedMedia = ({ source, id }) => {

  if (source==="youtube") {
    return(
      <div className="embedMedia">
          <iframe
            title={`Youtube video ${id}`}
            width="100%"
            height="400"
            src={`https://www.youtube-nocookie.com/embed/${id}`}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
    )
  }
  else if(source==="instagram"){
    return (
      <div className="embedMedia">
        <InstagramEmbed
          url={`https://instagr.am/${id}`}
          maxWidth={500}
          hideCaption={false}
          containerTagName="div"
          margin="auto"
        />
      </div>
    )
  }
  else if(source==="soundcloud"){
    return (
      <div className="embedMedia">
          <ReactSoundcloud url={`https://soundcloud.com/${id}`} />
        </div>
    )
  }
  else if(source==="twitter_tweet"){
    return (
      <div className="embedMedia">
        <TwitterTweetEmbed tweetId={id} />
      </div>
    )
  }
  else if(source==="twitter_video"){
    return (
      <div className="embedMedia">
        <TwitterVideoEmbed id={id} />
      </div>
    )
  } else {
    return "Problème avec le media"
  }

}

export default EmbedMedia
