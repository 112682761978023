import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import ReactMarkdown from "react-markdown"
import Layout from "../components/layout"
import SEO from "../components/seo"
import EmbedMedia from "../components/embedMedia"
import CardNews from "../components/cardNews"
import CardSmall from "../components/cardSmall"
import QuickPost from "../components/quickPost"
import TweetButton from "../components/tweetButton"
import AmazonAds from "../components/amazonAds"
import { displayCountryName, createSlug } from "../components/utils"
import { css } from "@emotion/core"
import { colors, mq, emojiBounce } from "../components/styles"

const newsTemplate = ({ pageContext, data }) => {
  const {
    bait,
    title,
    emoji,
    image,
    content,
    media_source,
    media_id,
    createdAt,
    type,
    createdAtReadable,
    artists,
    countries,
  } = data.strapiPosts

  const asideSuggestions = () => {
    let contentSize = content ? content.length : 0
    let limit
    // adjust number of cardSmall by comparing with length of actual article content
    contentSize < 1000 && (limit = 7)
    contentSize < 500 && (limit = 4)
    return data.countryStrapiPosts.edges.slice(0, limit)
  }

  const ogImage = {
    // prepare image data do send to SEO comp. with src and resolution data
    src: image.childImageSharp.fluid.src,
    resolutions: image.childImageSharp.resolutions,
  }

  return (
    <Layout>
      <SEO
        title={bait ? bait : title}
        description={content && content}
        image={ogImage}
        url={createSlug(type.name, createdAt, title)}
      />
      <div className="pageNews" css={pageNews}>
        <section className="hero">
          <figure className="image">
            <div className="overlayHero">
              <h1 className="title is-1 is-size-3-touch">{title}</h1>
              {countries &&
                countries.map((country, i) => {
                  return (
                    <span key={i} className="tag is-light">
                      {displayCountryName(country.code)}
                    </span>
                  )
                })}
            </div>
            <div className="underlayImageContainer">
              <Img
                className="underlayImage"
                fluid={image.childImageSharp.fluid}
              />
            </div>
          </figure>
        </section>
        <section className="section">
          <div className="columns is-centered is-marginless  ">
            <div className="column amsq is-3 is-hidden-tablet-only ">
              {emoji && (
                <aside className="emoji  is-hidden-tablet">
                  <span>{emoji}</span>
                </aside>
              )}
              <div className="is-hidden-desktop has-text-centered">
                <iframe
                  src={`https://rcm-eu.amazon-adsystem.com/e/cm?o=8&p=22&l=ur1&category=ebfd&banner=06Z546P0VSWFV5PYVH82&f=ifr&linkID=fbe6b60d0286949e8c14a90684fe75c0&t=francemixtape-21&tracking_id=francemixtape-21`}
                  width="250"
                  height="250"
                  scrolling="no"
                  border="0"
                  marginwidth="0"
                  style={{ border: "none" }}
                  frameborder="0"
                ></iframe>
              </div>
              {/* <div className="hcArtistSpons is-hidden-desktop">
                <a href="mailto:admin@hauteculture.com?subject=Promouvoir%20mon%20Projet%20sur%20HauteCulture&body=Bonjour, je souhaiterais promouvoir mon projet sur HauteCulture... ">
                  <img src="/assets/images/hcad_100.jpg" alt="ad" />
                </a>
              </div> */}
              <iframe
                title="iframe amzn on desktop"
                className="is-hidden-touch"
                src={`https://rcm-eu.amazon-adsystem.com/e/cm?o=8&p=14&l=ur1&category=channels&banner=0SANTWT8M1G2NGG3GX82&f=ifr&linkID=e054a504d8410a831181884713b3d310&t=francemixtape-21&tracking_id=francemixtape-21`}
                width="160"
                height="600"
                scrolling="no"
                border="0"
                marginWidth="0"
                style={{ border: "none" }}
                frameBorder="0"
              />
            </div>
            <div className="column is-6 is-7-tablet is-6-desktop">
              {emoji && (
                <aside className="emoji is-hidden-touch">
                  <span>{emoji}</span>
                </aside>
              )}
              <article className="container">
                {bait && (
                  <h2 className="subtitle is-4 is-size-5-touch">{bait}</h2>
                )}
                <p>
                  <time dateTime={createdAt} className="is-size-6">
                    {createdAtReadable}
                  </time>
                  <br />
                </p>
                <hr />
                <div className="is-hidden-touch has-text-centered">
                  <iframe
                    src={`https://rcm-eu.amazon-adsystem.com/e/cm?o=8&p=12&l=ur1&category=amu&banner=09N37396DECYFBSX1DR2&f=ifr&linkID=df1897a76e43ea93a8e56e915cbf3572&t=francemixtape-21&tracking_id=francemixtape-21`}
                    width="300"
                    height="250"
                    scrolling="no"
                    border="0"
                    marginwidth="0"
                    style={{ border: "none" }}
                    frameborder="0"
                  ></iframe>
                </div>
                {/* <div className="hcArtistSpons is-hidden-touch">
                  <a href="mailto:admin@hauteculture.com?subject=Promouvoir%20mon%20Projet%20sur%20HauteCulture&body=Bonjour, je souhaiterais promouvoir mon projet sur HauteCulture... ">
                    <img src="/assets/images/hcad_100.jpg" alt="ad" />
                  </a>
                </div> */}
                {pageContext.quickPost && (
                  <QuickPost
                    musicTitle={data.strapiPosts.music_title}
                    albumTitle={data.strapiPosts.album_title}
                    artist={artists[0].name}
                    producer={data.strapiPosts.producer}
                    director={data.strapiPosts.director_realisateur}
                  />
                )}
                <ReactMarkdown
                  className="is-size-5 contentArticle"
                  source={content && content}
                  escapeHtml={true}
                />
                <br />
                {artists[0] && (
                  <p className="is-size-5">
                    {artists[0].name}
                    {artists[1]
                      ? `, ${artists[1].name}, leurs albums`
                      : `, ses albums`}{" "}
                    et des millions d’autres sont disponible sur Amazon Music.
                    <a
                      href="https://www.amazon.fr/gp/dmusic/promotions/AmazonMusicUnlimited?tag=francemixtape-21"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Profitez dès maintenant des 30 jours gratuits!
                    </a>{" "}
                    Inclus le mode hors-ligne, pas de pub…
                    {/* Et si cette quarantaine était l’occasion d’écouter le
                    dernier album de {artists[0].name}{" "}
                    {artists[1] && ` ou de ${artists[1].name}`}? Haute Culture
                    vous recommande de tester amazon Music, en ce moment c’est
                    gratuit pendant 3 mois !
                    <br />
                    <a
                      href="https://www.amazon.fr/gp/dmusic/promotions/AmazonMusicUnlimited?tag=francemixtape-21"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Inscrivez-vous avec notre lien et testez leur service en
                      ligne. Inclus le mode hors-ligne, pas de pub, 50 millions
                      de titres…
                    </a> */}
                    .
                  </p>
                )}
                <iframe
                  title="iframe amzn on mobile"
                  className="is-hidden-desktop"
                  src={`https://rcm-eu.amazon-adsystem.com/e/cm?o=8&p=12&l=ur1&category=channels&banner=05JQMBH8CKJCTTVDX682&f=ifr&linkID=a5031884acc02694aa90d5f0b0772f66&t=francemixtape-21&tracking_id=francemixtape-21`}
                  scrolling="no"
                  border="0"
                  marginWidth="0"
                  style={{
                    border: "none",
                    width: "360px",
                    height: "250px",
                    maxWidth: "100%",
                  }}
                  frameBorder="0"
                />
                {/* <AmazonAds ads={pageContext.amazonAds} /> */}
              </article>
              <div className="social-share container has-text-justified">
                <TweetButton
                  emoji={emoji !== null && emoji}
                  bait={bait ? bait : title}
                  slug={createSlug(type.name, createdAt, title)}
                />
              </div>
              {media_source !== "youtube" && media_id && (
                <EmbedMedia source={media_source} id={media_id} />
              )}
            </div>
            <aside className="column is-3 is-hidden-mobile is-5-tablet is-3-desktop ">
              <h2 className="subtitle is-4 is-size-5-touch">
                {data.strapiPosts.countries[0] &&
                  data.globalYaml.index[
                    `title_${data.strapiPosts.countries[0].code}`
                  ]}
              </h2>

              <ol>
                {asideSuggestions().map((suggestion, i) => {
                  return (
                    <li key={i} className="media is-marginless is-paddingless ">
                      <CardSmall news={suggestion.node} displaySneakPeek={25} />
                    </li>
                  )
                })}
                <li className="media is-marginless is-paddingless ">
                  <article className="media is-marginless">
                    <figure
                      className="media-left image is-64x64"
                      style={{ background: "#fe1371" }}
                    >
                      {/* <Img
                        className="card-image"
                        fluid={news.image.childImageSharp.fluid}
                      /> */}
                    </figure>
                    <div className="media-content">
                      <div className="content">
                        <h6 className="title is-6">
                          Création de site web Gatsby à Paris
                        </h6>
                        <p className="subtitle is-6">
                          Réalisation de site web vitrine pour annoncer un
                          évènement ou presenter votre
                          entreprise/projet/produit/startup...
                          <br />
                          <a
                            href="https://josselin.pro"
                            style={{
                              color: "#fe1371",
                              fontWeight: "600",
                              fontSize: "1rem",
                            }}
                          >
                            Créons votre site web
                          </a>
                        </p>
                      </div>
                    </div>
                  </article>
                </li>
              </ol>
              {pageContext.hasArtistsSuggestion && (
                <div className="voir-aussi">
                  <h2 className="subtitle is-4 is-size-5-touch">Voir aussi</h2>
                  <ol>
                    {data.artistsStrapiPosts.edges.map((suggestion, i) => {
                      return (
                        <li
                          key={i}
                          className="media is-marginless is-paddingless "
                        >
                          <CardSmall
                            news={suggestion.node}
                            displaySneakPeek={25}
                          />
                        </li>
                      )
                    })}
                  </ol>
                </div>
              )}
            </aside>
          </div>
          <div className="mobileCountryPosts columns is-hidden-tablet">
            <ol>
              {asideSuggestions()
                .slice(0, 4)
                .map((suggestion, i) => {
                  return (
                    <li key={i} className="media is-marginless is-paddingless ">
                      <CardSmall news={suggestion.node} displaySneakPeek={25} />
                    </li>
                  )
                })}
            </ol>
          </div>
        </section>

        {media_source === "youtube" && media_id && (
          <EmbedMedia source={media_source} id={media_id} />
        )}
        <div className="columns is-centered is-marginless">
          <div className="column">
            <AmazonAds ads={pageContext.amazonAds} />

            <ol className="is-hidden-tablet">
              {pageContext.hasArtistsSuggestion &&
                data.artistsStrapiPosts.edges
                  .slice(0, 3)
                  .map((suggestion, i) => {
                    return (
                      <li
                        key={i}
                        className="media is-marginless is-paddingless "
                      >
                        <CardSmall
                          news={suggestion.node}
                          displaySneakPeek={25}
                        />
                      </li>
                    )
                  })}
            </ol>
            {/* <a
              className="amrect"
              href="https://www.amazon.fr/gp/dmusic/promotions/AmazonMusicUnlimited?tag=francemixtape-21"
              target="_blank"
               rel="noopener noreferrer"
            >
              <Img fluid={data.amrect.fluid} />
            </a> */}
            <iframe
              title="iframe amzn on desktop 2"
              className="is-hidden-touch"
              src={`https://rcm-eu.amazon-adsystem.com/e/cm?o=8&p=48&l=ur1&category=amu&banner=1VS99ZFVBQB4YZF99K02&f=ifr&linkID=92cae257e3190b7809f93198c0bd432c&t=francemixtape-21&tracking_id=francemixtape-21`}
              width="728"
              height="90"
              scrolling="no"
              border="0"
              marginWidth="0"
              style={{ border: "none", margin: "auto", display: "flex" }}
              frameBorder="0"
            />
            <iframe
              title="iframe amzn on mobile 2"
              className=" is-hidden-desktop"
              src={`https://rcm-eu.amazon-adsystem.com/e/cm?o=8&p=12&l=ur1&category=amu&banner=1J9ZEANZN7CGS5HVA3G2&f=ifr&linkID=49eb4ed01aa7ae20d48a2c2517566360&t=francemixtape-21&tracking_id=francemixtape-21`}
              scrolling="no"
              border="0"
              marginWidth="0"
              style={{
                border: "none",
                margin: "auto",
                display: "flex",
                width: "300px",
                height: "250px",
                maxWidth: "100%",
              }}
              frameBorder="0"
            />
          </div>
        </div>
      </div>
      <section className="section">
        <div className="container">
          <div className="columns is-multiline is-centered is-marginless">
            {pageContext.lastArticles.map((suggestion, i) => {
              return (
                <div key={i} className="column is-4">
                  <CardNews
                    news={suggestion.node}
                    displayCountry={true}
                    displayDate={true}
                    displaySneakPeek={60}
                  />
                </div>
              )
            })}
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default newsTemplate

export const queryNewsTemplate = graphql`
  query(
    $idNews: String!
    $quickPost: Boolean!
    $countryNews: String!
    $hasArtistsSuggestion: Boolean!
    $artistsSuggestion: [String]
  ) {
    strapiPosts(id: { eq: $idNews }) {
      id
      bait
      title
      emoji
      content
      music_title @include(if: $quickPost)
      album_title @include(if: $quickPost)
      producer @include(if: $quickPost)
      director_realisateur @include(if: $quickPost)
      media_source
      media_id
      createdAt
      createdAtReadable: createdAt(
        formatString: "D MMMM YYYY à HH:mm"
        locale: "fr_FR"
      )
      artists {
        id
        name
        country
      }
      countries {
        code
      }
      type {
        name
      }
      # imageWFilter: image {
      #   childImageSharp {
      #     resolutions {
      #       height
      #       width
      #     }
      #     fluid(
      #       duotone: { highlight: "#f00e2e", shadow: "#192550", opacity: 50 }
      #     ) {
      #       ...GatsbyImageSharpFluid_withWebp_tracedSVG
      #     }
      #   }
      # }
      image {
        childImageSharp {
          resolutions {
            height
            width
          }
          fluid {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
    amsq: imageSharp(fluid: { src: { regex: "/amsq/" } }) {
      fluid {
        ...GatsbyImageSharpFluid_withWebp_tracedSVG
      }
    }
    amrect: imageSharp(fluid: { src: { regex: "/amrect/" } }) {
      fluid {
        ...GatsbyImageSharpFluid_withWebp_tracedSVG
      }
    }
    countryStrapiPosts: allStrapiPosts(
      sort: { order: DESC, fields: createdAt }
      filter: {
        countries: { elemMatch: { code: { eq: $countryNews } } }
        id: { ne: $idNews }
        strapiId: { nin: $artistsSuggestion }
      }
      limit: 10
    ) {
      edges {
        node {
          ...StrapiPosts
        }
      }
    }
    artistsStrapiPosts: allStrapiPosts(
      sort: { order: DESC, fields: createdAt }
      filter: { strapiId: { in: $artistsSuggestion } }
      limit: 5
    ) @include(if: $hasArtistsSuggestion) {
      edges {
        node {
          ...StrapiPosts
        }
      }
    }

    globalYaml: globalYaml {
      index {
        title
        title_fra
        title_usa
        title_lat
        title_bra
      }
    }
  }
`

const pageNews = css`
  .hero {
    figure {
      position: relative;
      .overlayHero {
        display: flex;
        position: relative;
        flex: 1;
        width: 100%;
        z-index: 10;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        justify-self: center;
        background-color: rgba(0, 0, 0, 0.4);
        padding: 30px;
        min-height: 200px;
        ${mq[1]} {
          min-height: 300px;
          padding: 150px;
        }
        .title.is-1 {
          color: ${colors.white};
          text-shadow: 0px 0px 25px rgb(0, 0, 0);
          margin-left: 15px;
          margin-right: 15px;
        }
      }
      .underlayImageContainer {
        position: absolute;
        z-index: 5;
        top: 0;
        height: 100%;
        width: 100%;
        .underlayImage {
          height: 100%;
        }
      }
    }
  }
  .emoji {
    background: white;
    width: 80px;
    height: 80px;
    position: absolute;
    z-index: 20;
    margin-top: -100px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 2.2rem;
    border: solid 1px #eee;
    transition: transform 0.3s ease-in-out;
    animation: ${emojiBounce} 1s infinite;
  }
  .amsq,
  .amrect {
    align-self: center;
    margin: auto;
  }

  article {
    .subtitle {
      color: inherit;
    }
    .contentArticle {
      p {
        padding: 15px 0;
        font-family: "Vollkorn";
      }
    }
    > p {
      font-family: "Vollkorn";
    }
    blockquote {
      font-family: "Vollkorn";
      margin: 30px;
      padding: 0 15px;
      border-left: solid 5px lightgrey;
      font-size: 1.7rem;
      font-style: italic;
      font-weight: lighter;
    }
  }
  .embedMedia {
    margin: auto;
    div {
      /* isnta */
      display: flex;
      justify-content: center;
    }
  }
  .mobileCountryPosts {
    margin-top: 1rem;
  }
  .voir-aussi {
    margin-top: 1rem;
  }
  .social-share {
    margin-top: 1rem;
  }
  .hcArtistSpons {
    position: relative;
    max-width: 300px;
    /* height: 250px; */
    margin: auto;
    margin-bottom: 1rem;
    &:after {
      content: "publicité";
      position: absolute;
      background: #eeeeee;
      opacity: 0.9;
      color: darkgray;
      font-size: 10px;
      top: -21px;
      right: 0;
      padding: 3px 5px;
    }
  }
`
